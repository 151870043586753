#results {
    table-layout: fixed;
}

#results>tbody>tr:not(:last-child)>td:first-child {
    border-left: 1px solid #000000;
}

#results>tbody>tr:last-child>td {
    border-top: 1px solid #000000;
    position: relative;
    vertical-align: top;
}

#results>tbody>tr:last-child>td>div {
    vertical-align: top;
    text-align: center;
    /* position: absolute; */
    /* left: 50%; */
    /* transform-origin: center left;
    transform: rotate(45deg) translate(-5px, -5px); */
}

#results>tbody>tr>td {
    text-align: center;
    font-size: 80%;
    font-weight: bold;
}

label {
    max-width: 100%;
}

.MuiGrid-item {
    overflow: hidden;
}